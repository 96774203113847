import consumer from "./consumer"

$(document).ready(function() {

  var userId = $('#userId').data('id')
  consumer.subscriptions.create({ channel: "NotificationChannel", user_id: userId }, {
    connected() {
      console.log('connected to notify channel')
    },

    disconnected() {
      console.log('conection to notify channel is terminated')
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // We will notify consumer that the file is ready to be downloaded or if the report needs to be rerun
      $(".report-notification-alert").html('')
      $('.report-notification').show();
      const download_path = data['download_path']
      const file_name = data['file_name']
      const file_size = data['file_size']
      const no_prices = data['no_prices']
      var html_notification
      if (file_name == 'LIMIT REACHED') {
        $(".report-notification-alert").addClass('alert-danger')
        html_notification = `<p>Report size limit exceeded!</p><p>Please rerun the report with either a shorter date range or fewer providers/categories/subcategories/brand names selected</p>`
      }else if(no_prices == true){
          $(".report-notification-alert").addClass('alert-success')
          html_notification = `<p>No prices found for the selected parameters</p>`
        }
      else {
        $(".report-notification-alert").addClass('alert-success')
        html_notification = `<p>The file is ready to be downloaded</p><p>Please click here <a href=${download_path} target="_blank" download> ${file_name}</a> (${file_size} mb)</p>`
      }

      $(".report-notification-alert").html('').append(html_notification);
    }
  });
})
